import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import moment from 'moment'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getNoteDetails } from "../../actions/notes";

const ViewNotes = (props) => {
    const [loading, setLoading] = useState(false);
    const [noteDetail, setNoteDetail] = useState({});

    //get note detail by ID
    const getNoteDetail = (userId) => {
        let params = {
            id: userId,
            token: props.auth.token,
        }
        setLoading(true);
        props.getNoteDetails(params, (res) => {
            setLoading(false);
            if (res) {
                setNoteDetail(res.data)
            }
            else {
                toast.error(res.message);
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    useEffect(() => {
        getNoteDetail(props.selectedNoteId);

        return () => {
            setNoteDetail({});
        }
    }, [])

    return (
        <>
            <div className="row ps-2 pe-2 pt-3 pb-4 m-0">
                {loading ?
                    <div className="col-12 text-center p-5 mt-5 mb-5">
                        <div className="spinner-grow text-success" role="status"></div>
                    </div>
                    :
                    <>
                        <div className="formDetail_">
                            <div className="row">
                                <div className="col-lg-6">

                                    {/* <div className="userDetail_">
                                <label className="label mr-2" for="lfname">Full Name</label>
                                <span>{userDetail && userDetail.user_profiles ? userDetail.user_profiles.fullnames : "N/A"} </span>
                            </div><br /> */}
                                    <div className="userDetail_">
                                        <label className="label mr-2" for="lfname">Status:</label>
                                        <span>{noteDetail && noteDetail.is_active === true ? "Active" : "In-Active"} </span>
                                    </div><br />
                                </div>
                                <div className="col-lg-6">
                                    <div className="userDetail_">
                                        <label className="label mr-2" for="lfname">Created At:</label>
                                        <span> {moment(noteDetail?.created_at ? noteDetail?.created_at : "N/A").format("YYYY/MM/DD")}</span>
                                    </div><br />
                                </div>
                            </div>
                        </div>

                        <div className="form-group mt-2 mb-2 col-md-12">
                            <label className="label_text" htmlFor="inputPassword">Note</label>
                            {noteDetail && noteDetail.note ?
                                <CKEditor
                                    name="content"
                                    id="inputName"
                                    className="ckp"
                                    editor={ClassicEditor}
                                    disabled
                                    // config={{
                                    //     removePlugins: ['MediaEmbed'],

                                    // }}
                                    config={{
                                        removePlugins: [ "EasyImage","ImageUpload","MediaEmbed" ]
                                      }}
                                    data={noteDetail && noteDetail.note}
                                    onReady={(editor) => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log("Editor is ready to use!", editor);
                                    }}
                                // onChange={(event, editor) => {
                                //     const data = editor.getData()
                                //     setNote(data)

                                // }
                                // }
                                />
                                : ""
                            }

                        </div>
                    </>
                }
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    getNoteDetails: bindActionCreators(getNoteDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewNotes);