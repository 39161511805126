import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import password from '../../public/images/password.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import LockImage from "../../public/images/lock-.svg";
import { changePassword } from '../../actions/settings';
const eye = <FontAwesomeIcon icon={faEye} />

const ChangePassword = (props) => {
  let { resetPasswordToken } = useParams();
  //const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownNewPassword, setPasswordShownNewPassword] = useState(false);
  const [passwordShownConfirmPassword, setPasswordShownConfirmPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [formData, setFormData] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: ""
  });


  // const [password, setPassword] = useState("");

  // const [passwordVisibility, setPasswordVisibility] = useState(false);

  //onchange input field
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessages({ ...errorMessages, [name]: "" });
  }


  //password field show or hide
  const togglePasswordVisiblityoldpassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  //password field show or hide
  const togglePasswordVisiblitynewpassword = () => {
    setPasswordShownNewPassword(passwordShownNewPassword ? false : true);
  };

  //password field show or hide
  const togglePasswordVisiblityconfirmpassword = () => {
    setPasswordShownConfirmPassword(passwordShownConfirmPassword ? false : true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.oldpassword) {
      toast.error("Please enter your old password");
      return;
    }

    else if (!formData.newpassword) {
      toast.error("Please enter your new password");
      return;
    }
    else if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(formData.newpassword)) {
      // Regex with small and capital letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
      // Regex with only small letter: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/
      toast.error("Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character");
      return;
    }
    else if (!formData.confirmpassword) {
      toast.error("Please enter confirm new Password");
      return;
    }
    else if (formData.confirmpassword !== formData.newpassword) {
      toast.error("Password and confirm password doesn't match ");
      return;
    }

    let params = {
      old_password: formData.oldpassword,
      new_password: formData.newpassword,
      confirm_password: formData.confirmpassword,
      token: props.auth.token
    }

    setIsLoading(true);
    props.changePassword(params, res => {
      setIsLoading(false);
      if (res.status) {
        toast.success(res.message);
        props.closeModal();
      }
      else {
        toast.error(res.message);
      }
    },
      err => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  // useEffect(() => {
  //   document.title = "Change Password"; 
  // }, []);




  return (
    <>
      <form onSubmit={(e) => { handleSubmit(e) }} className="form_horizontal row ps-3 pe-3" >
        <div className="form-group mt-3 mb-2 col-md-12">
          <label className="label_text" htmlFor="inputPassword">Old Password</label>
          <div className="groupInputs_  mb-4">
            <label className="changepass">
              <img src={password} />
            </label>
            <input
              className="form-control change_password"
              placeholder="Old Password"
              name="oldpassword"
              type={passwordShown ? "text" : "password"}
              value={formData.oldpassword}
              onChange={(e) => { handleChange(e) }}
            />
            {/* <span className="change_password_">
              <i onClick={togglePasswordVisiblityoldpassword}>{eye}</i>{" "}
            </span> */}
            <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblityoldpassword(!passwordShown) }}>
              <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
            </span>
          </div>
        </div>
        <div className="form-group mt-3 mb-2 col-md-12">
          <label className="label_text" htmlFor="inputPassword">New Password</label>
          <div className="groupInputs_  mb-4">
            <label className="changepass">
              <img src={password} />
            </label>
            <input
              className="form-control change_password"
              placeholder="New Password"
              name="newpassword"
              type={passwordShownNewPassword ? "text" : "password"}
              value={formData.newpassword}
              onChange={(e) => { handleChange(e) }}
            />
            {/* <span className="change_password_">
              <i onClick={togglePasswordVisiblitynewpassword}>{eye}</i>{" "}
            </span> */}
            <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblitynewpassword(!passwordShownNewPassword) }}>
              <span className={passwordShownNewPassword ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
            </span>
          </div>
        </div>
        <div className="form-group mt-3 mb-2 col-md-12">
          <label className="label_text" htmlFor="inputPassword">Confirm Password</label>
          <div className="groupInputs_  mb-4">
            <label className="changepass">
              <img src={password} />
            </label>
            <input
              className="form-control change_password"
              placeholder="Confirm Password"
              name="confirmpassword"
              type={passwordShownConfirmPassword ? "text" : "password"}
              value={formData.confirmpassword}
              onChange={(e) => { handleChange(e) }}
            />
            {/* <span className="change_password_">
              <i onClick={togglePasswordVisiblityconfirmpassword}>{eye}</i>{" "}
            </span> */}
            <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblityconfirmpassword(!passwordShownConfirmPassword) }}>
              <span className={passwordShownConfirmPassword ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
            </span>
          </div>
        </div>
        <div className="form-group text-center col-md-12 mt-3 mb-3">
          &nbsp;&nbsp;&nbsp;
          <button className="btnTheme" disabled={isLoading} type="submit">
            {isLoading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            <span>Update</span>
          </button>
        </div>
        {/* <!-- <div id="or-separator" className="or-separator mt-3"><span className="or-text">or continue with</span></div> --> */}
      </form>
    </>
  )

}
const mapStateToProps = state => ({
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  changePassword: bindActionCreators(changePassword, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);




