if (process.env.NODE_ENV === "production") {

    module.exports = {
   
   
     
       // Client's URL
       // AUTH_API_URL: "https://shepherduser-uat.itechnolabs.tech:6001/api/v1",
       // AUTH_API_BASE_URL: "https://shepherduser-uat.itechnolabs.tech:6001",
       // API_URL:"https://shepherdapp.itechnolabs.tech/api/v1",
       // BASE_URL:"https://shepherdapp.itechnolabs.tech"
   
       // Development's URL
       //AUTH_API_URL: "http://hha-notes.itechnolabs.tech:3059/api/v1",
       AUTH_API_URL: "https://hha-notes.itechnolabs.tech/api/v1",
       AUTH_API_BASE_URL: "https://usersherperd.itechnolabs.tech",
       API_URL:"https://sheperdstagging.itechnolabs.tech:3055/api/v1",
       BASE_URL:"https://sheperdstagging.itechnolabs.tech:3055",
       
   
       // Dev Url
       // https://usersherperd.itechnolabs.tech - User Instance
       // https://sheperdstagging.itechnolabs.tech:3055 - Shepherd Intance
       // Client Url
       // https://shepherduser-uat.itechnolabs.tech:6001 - User Instance
       // https://shepherdapp.itechnolabs.tech - Shepherd,
     };
   
   } else {
   
    module.exports = {
   
       // Local's URL
       // AUTH_API_URL: "http://localhost:8000/api/v1",
       // AUTH_API_BASE_URL: "http://localhost:8000",
       // API_URL: 'http://localhost:7500/api/v1',
       // BASE_URL:"http://localhost:7500",
   
   
       // Client's URL
       // AUTH_API_URL: "https://shepherduser-uat.itechnolabs.tech:6001/api/v1",
       // AUTH_API_BASE_URL: "https://shepherduser-uat.itechnolabs.tech:6001",
       // API_URL: 'https://shepherdapp.itechnolabs.tech/api/v1',
       // BASE_URL:"https://shepherdapp.itechnolabs.tech",
   
   
       // Development's URL
       // AUTH_API_URL: "https://shepherduser-uat.itechnolabs.tech:6001/api/v1",
       // AUTH_API_BASE_URL: "https://shepherduser-uat.itechnolabs.tech:6001",
       // API_URL: 'https://shepherdapp.itechnolabs.tech/api/v1',
       // BASE_URL:"https://shepherdapp.itechnolabs.tech",
       // LOCAL_API_URL: "http://localhost:3000/api/v1"
   
       // Development's URL
      //  AUTH_API_URL: "http://hha-notes.itechnolabs.tech:3059/api/v1",
       AUTH_API_URL: "https://hha-notes.itechnolabs.tech/api/v1",
       AUTH_API_BASE_URL: "https://usersherperd.itechnolabs.tech",
       API_URL: 'https://sheperdstagging.itechnolabs.tech:3055/api/v1',
       BASE_URL:"https://sheperdstagging.itechnolabs.tech:3055",
     };
   
   }
   