import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { userLogOut } from "../../actions/user";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import CustomModal from './CustomModal';
import ChangePassword from './changePassword';

const Header = (props) => {
    const history = useHistory();
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: ""
    });
    
    const handleOnCloseModal = () => {
        setModalDetail({
            show: false,
            title: "",
            flag: ""
        });
        setKey(Math.random());
    }


    // user logout
    const userLogout = () => {
        Swal.fire({
            title: 'Do you want to logout?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            customClass: {
                confirmButton: 'sweet-alert-confirm-btn btn-primary'
            },
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                let params = {
                    token: props.auth.token
                }

                props.userLogout(params, res => {
                    if(res.status){
                        toast.success(res.message);
                        history.push('/login');
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                err => {
                    toast.error(err.message);
                })
            } else if (result.isDenied) {
                // 
            }
        })
        
    }

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <a onClick={() => {history.push('/')}} className="nav-link">Home</a>
                    </li> */}
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <a href="#" className="nav-link">Contact</a>
                    </li> */}
                </ul>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-user"></i>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            {/* <div className="dropdown-divider"></div>
                            <a href="{{route('user.profile')}}" className="dropdown-item">
                                <i className="fas fa-user mr-2"></i> Profile
                            </a>

                            <div className="dropdown-divider"></div>
                            <a href="{{route('user.updateProfile')}}" className="dropdown-item">
                                <i className="fas fa-edit mr-2"></i> Update Profile
                            </a> */}

                            <div className="dropdown-divider"></div>
                            <a onClick={() => {setModalDetail({show: true, title: 'Change Password'}); setKey(Math.random())}} className="dropdown-item">
                                <i className="fas fa-key mr-2"></i> Change Password
                            </a>

                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={() => {userLogout()}}>
                                <i className="fas fa-sign-out-alt mr-2"></i> Logout
                            </a>
                        </div>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <i className="fas fa-expand-arrows-alt"></i>
                        </a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                        <i className="fas fa-th-large"></i>
                        </a>
                    </li> */}
                </ul>
            </nav>
                {/* <!-- start Modal update profile  --> */}
                <CustomModal
                key = {key}
                show = {modalDetail.show}
                backdrop = "static"
                title = {modalDetail.title}
                child = {<ChangePassword closeModal={() => handleOnCloseModal()}/>}
                onCloseModal={() => handleOnCloseModal()}
            />                
            {/* <!-- end Modal update profile  --> */}
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    userLogout: bindActionCreators(userLogOut, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);