import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { editSelectedNote } from "../../actions/notes";

const EditNotes = (props) => {
    const selectedEditNote = props.selectedEditNote;
    const [loading, setLoading] = useState(false);
    const [note, setNote] = useState(selectedEditNote ? selectedEditNote.note : "");

    //edit note
    const handleUpdate = () => {
        if (!note) {
            toast.error("Please enter note");
            return;
        }
        let params = {
            token: props.auth.token,
            note: note,
            id: selectedEditNote ? selectedEditNote.id : "",
        }
        setLoading(true);
        props.updateNote(params, res => {
            setLoading(false);
            if (res.status) {
                toast.success(res.message);
                props.refreshgetNotes();
                props.closeModal();
            }
            else {
                toast.error(res.message);
            }
        },
            err => {
                setLoading(false);
                toast.error("Something went wrong!");
            })

    };

    useEffect(() => {
        //
    }, [])

    return (
        <>
            <form className="form_horizontal row ps-3 pe-3" role="form" method="POST" action="" >
                <div className="form-group mt-2 mb-2 col-md-12">
                    <label className="label_text" htmlFor="inputPassword">Note</label>
                    <CKEditor
                        name="content"
                        id="inputName"
                        className="ckp"
                        editor={ClassicEditor}
                        // config={{
                        //     removePlugins: ['MediaEmbed'],

                        // }}
                        config={{
                            removePlugins: [ "EasyImage","ImageUpload","MediaEmbed", ]
                          }}
                        data={note}
                        onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData()
                            setNote(data)

                        }
                        }
                    />


                </div>
                <div className="form-group  col-md-12 mt-3 mb-3">
                    <button className="btn btn-danger" type="button" onClick={props.closeModal} >
                        <span>Close</span>
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button className="btn btn-primary" disabled={loading} type="button" onClick={() => { handleUpdate() }}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        &nbsp;&nbsp;
                        <span>Update</span>
                    </button>
                </div>
            </form>
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    updateNote: bindActionCreators(editSelectedNote, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditNotes);
