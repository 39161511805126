import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import { deleteUsers, getAllUsers, getChangeStatus, getUserDetails, resetUserPassword } from '../../actions/user';
import moment from 'moment';
import Swal from 'sweetalert2';
import View from './view';
import EditUser from './edit';
import AddUser from './add';
import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CustomModal from "../../components/shared/CustomModal";
import PaginationSetting from '../../constants/pagination';

const User = (props) => {
    const location = useLocation();
    const filter = location.state ? location.state.filter : '';
    const [pagination, setPagination] = useState(PaginationSetting)
    const [users, setUsers] = useState([])
    const [selectedUserId, setSelectedUserId] = useState([])
    const [selectedEditUser, setSelectedEditUser] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [checked, setChecked] = useState(true);
    const [page, setPage] = useState(1);
    const [key, setKey] = useState(Math.random());
    const [modalDetail, setModalDetail] = useState({
        show: false,
        title: "",
        flag: ""
    });

    //get all users
    const getUsers = (clearSearch = false, active) => {
        let params = {
            token: props.auth.token,
            page: page,
            limit: pagination.recordsPerPage,
        }

        if (!clearSearch && searchInput && searchInput != '') {
            params = { ...params, search: searchInput.trim(), page: 1 };
        }
        else if (filter) {
            params = { ...params, page: 1, status: filter };
        }
        else if (checked) {
            params = { ...params, page: 1, };
        }

        setLoading(true);
        props.getAllUsers(params, (res) => {
            setLoading(false);
            if (res.statusCode == 200 || res.statusCode == 201) {
                setUsers(res.data.users.users || res.data.users)
                setPagination((prevState) => ({ ...prevState, totalPages: res.data.users.total_pages, totalRecords: res.data.users.total }));

            }
            else {
                setUsers([]);
                setPagination((prevState) => ({ ...prevState, totalPages: 0, totalRecords: 0 }));

                if (res.statusCode !== 404) {
                    toast.error(res.message);
                }
            }
        },
            (err) => {
                setLoading(false);
                toast.error(err.message);
            })
    }

    //get status change by Id
    const getChangeStatus = (id) => {

        Swal.fire({
            title: 'Are you sure want to change status?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    token: props.auth.token,
                    id: id
                }
                setLoading(true);
                props.getChangeStatus(params, (res) => {
                    setLoading(false);
                    if (res.statusCode == 200 || res.statusCode == 201) {
                        getUsers();
                    }
                    else {
                        if (res.statusCode !== 404) {
                            toast.error(res.message);
                        }
                    }
                },
                    (err) => {
                        setLoading(false);
                        toast.error(err.message);
                    })
            }
            else if (result.isDenied) {
                // 
            }
        })
    }


    //close modal
    const handleOnCloseModal = () => {
        setModalDetail({ show: false, title: '', flag: "" });
        setKey(Math.random());
    }

    //get id of selected view user
    const viewUserDetail = (userId) => {
        setSelectedUserId(userId);
        setTimeout(() => {
            setModalDetail({ show: true, title: 'User Details', flag: "view" });
            setKey(Math.random());
        }, 200);
    }


    //get id of selected user
    const editUserDetails = (userId) => {
        let selectedEditUser = users.find(item => item.id == userId);
        setSelectedEditUser(selectedEditUser);
        setTimeout(() => {
            setModalDetail({ show: true, flag: 'edit', title: 'Edit User' });
            setKey(Math.random());
        }, 200);
    }


    //onclick active/deactive user 
    const handleClick = (event) => {
        let active = event.target.value;
        getUsers(event, active)
    }


    //search input
    const onChangeSearchInput = (searchValue) => {
        setSearchInput(searchValue);
    }

    //change status active/inactive
    const handleChange = (event, value) => {
        setChecked(event.target.checked);
        getChangeStatus(value)
    };

    // delete user
    const deleteUser = (userId) => {
        Swal.fire({
            title: 'Are you sure want to remove this user?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Yes',
            denyButtonText: 'No',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                let params = {
                    id: userId,
                    token: props.auth.token
                }
                props.deleteUser(params, res => {
                    if (res.status) {
                        toast.success(res.message);
                        getUsers();
                    }
                    else {
                        toast.error(res.message);
                    }
                },
                    err => {
                        toast.error("Something went wrong!");
                    });
            } else if (result.isDenied) {
                // 
            }
        })
    };


  // reset user password
  const resetUserPassword = (email) => {
    Swal.fire({
        title: 'Are you sure want to reset this user password?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
    }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            let params = {
                email: email,
                token: props.auth.token
            }
            props.resetUserPassword(params, res => {
                if (res.status) {
                    toast.success(res.message);
                    getUsers();
                }
                else {
                    toast.error(res.message);
                }
            },
                err => {
                    toast.error("Something went wrong!");
                });
        } else if (result.isDenied) {
            // 
        }
    })
};

    

    useEffect(() => {
        document.title = "user";
        getUsers();
    }, [page])

    return (
        <>

            <div className="content-wrapper">
                <div class="contentMain">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12 col-md-6">
                                    <h1 className="m-0">Users &nbsp;&nbsp;
                                        <button type="button" className="btn btn-primary addBtn"
                                            onClick={() => { setModalDetail({ show: true, flag: 'add', title: 'Add New User' }); setKey(Math.random()); }}
                                        >
                                            <i className="fas fa-plus"></i> Add </button>&nbsp; &nbsp;

                                        {filter === "Active" ?

                                            <button onClick={handleClick} value="Active" type="button" className="btn btn-primary addBtn">
                                                <i className="fas fa-user-alt"></i> Active </button>

                                            :
                                            filter === "Inactive" ?
                                                <button onClick={handleClick} value="Inactive" type="button" className="btn btn-primary addBtn">
                                                    <i className="fas fa-user-slash"></i> Inactive </button>
                                                :
                                                ""
                                        }




                                    </h1>
                                </div>
                                <div className="col-sm-12 col-md-6 text-end">
                                    <form className='inputSearchForm'>
                                        <div className='input-group me-2'>
                                            <input type="text" className="form-control rounded" onChange={(e) => onChangeSearchInput(e.target.value)} placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                            <button className='inputClose' onClick={() => { onChangeSearchInput(""); getUsers(true) }} type="reset"> <i className="las la-times"></i> </button>
                                        </div>
                                        <button className='searchBtn' type="button" onClick={() => { return searchInput ? getUsers() : '' }}>Search</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Main content */}
                    <section className="content">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        {loading ?
                                            <div className="row p-5 mt-5 mb-5">
                                                <div className="col-12 text-center">
                                                    <div className="spinner-grow text-success" role="status"></div>
                                                </div>
                                            </div>
                                            :
                                            <> 
                                                <div className='table-responsive'>
                                                    <table style={{ width: '100%' }} className="table table-striped">
                                                        <thead style={{ fontSize: '15px' }}>
                                                            <tr>
                                                                <th style={{ borderTop: '0px' }} className='text-center'>Sr. No.</th>
                                                                <th style={{ borderTop: '0px' }}>Full Name</th>
                                                                <th style={{ borderTop: '0px' }}>Email</th>
                                                                <th style={{ borderTop: '0px' }}>Created At</th>
                                                                <th style={{ borderTop: '0px' }}>Status</th>
                                                                <th style={{ borderTop: '0px' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ fontSize: '15px' }}>
                                                            {users && users.length > 0 ?
                                                                <>
                                                                    {users.map((value, index) =>
                                                                        <tr key={index} >
                                                                            <td className='text-center'>{(page - 1) * pagination.recordsPerPage + index + 1}</td>
                                                                            <td>{value.user_profiles ? value.user_profiles.fullname : "--"}</td>
                                                                            <td>{value.email ? value.email : "--"}</td>
                                                                            <td>{moment(value.created_at ? value.created_at : "--").format("YYYY/MM/DD")}</td>
                                                                            <td style={{ paddingTop: "4px" }}>
                                                                                {value.is_active === true ?
                                                                                    <>
                                                                                        Active
                                                                                        <Switch
                                                                                            checked={true}
                                                                                            className="react-switch"
                                                                                            onChange={(e) => handleChange(e, value.id)}
                                                                                        />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        Inactive

                                                                                        <Switch
                                                                                            checked={false}
                                                                                            onChange={(e) => handleChange(e, value.id)}
                                                                                            className="react-switch"
                                                                                        />
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <button className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { editUserDetails(value.id) }}
                                                                                    style={{ background: '#17a2b8', color: 'white' }}>
                                                                                    <span>
                                                                                        <i className='fas fa-pencil-alt'></i>&nbsp;
                                                                                    </span>
                                                                                    Edit
                                                                                </button>
                                                                                <button
                                                                                    className='btn btn-sm btn-del-key internal mr-2'
                                                                                    onClick={() => { viewUserDetail(value.id) }}
                                                                                    style={{ background: '#17a2b8', color: 'white' }}
                                                                                >
                                                                                    <span> <i className='fa fa-eye'></i>&nbsp;</span>
                                                                                    View
                                                                                </button>
                                                                                <button className='btn btn-sm btn-del-key internal mr-2 delete_btn'
                                                                                    onClick={() => { deleteUser(value.id) }}
                                                                                >
                                                                                    <span>
                                                                                        <i className='fas fa-trash'></i>
                                                                                    </span>
                                                                                    Delete
                                                                                </button>
                                                                                <button className='btn btn-sm btn-del-key internal mr-2 reset_password'
                                                                                     onClick={() => { resetUserPassword(value.email) }}
                                                                                >
                                                                                    <span>

                                                                                        <i class="fa fa-key" aria-hidden="true"></i>
                                                                                    </span>
                                                                                    Reset Password
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                    }
                                                                </>
                                                                :
                                                                <tr>
                                                                    <td colSpan='7' style={{ textAlign: 'center' }}>No Data Found</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                                {pagination.totalRecords > pagination.recordsPerPage &&
                                                    <Box py={3} display="flex" justifyContent="center">
                                                        <Pagination
                                                            key={page}
                                                            count={pagination.totalPages ? pagination.totalPages : "" || 0}
                                                            color="secondary"
                                                            variant="outlined"
                                                            onChange={(e, value) => setPage(value)}
                                                            defaultPage={page}
                                                        />
                                                    </Box>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <CustomModal
                key={key}
                show={modalDetail.show}
                backdrop="static"
                showCloseBtn={true}
                isRightSideModal={true}
                child={modalDetail.flag == 'add'
                    ? <AddUser closeModal={() => handleOnCloseModal()} selectedUserId={selectedUserId} refreshgetUsers={() => { getUsers() }} />
                    : modalDetail.flag == "edit"
                        ? <EditUser closeModal={() => handleOnCloseModal()} selectedEditUser={selectedEditUser} refreshgetUsers={() => { getUsers() }} />
                        : <View closeModal={() => handleOnCloseModal()} selectedUserId={selectedUserId} refreshgetUsers={() => { getUsers() }} />

                }
                size="lg"
                title={modalDetail.title}
                // footerContent = {}
                // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
                onCloseModal={() => handleOnCloseModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
    getAllUsers: bindActionCreators(getAllUsers, dispatch),
    getUserDetails: bindActionCreators(getUserDetails, dispatch),
    getChangeStatus: bindActionCreators(getChangeStatus, dispatch),
    deleteUser: bindActionCreators(deleteUsers, dispatch),
    resetUserPassword: bindActionCreators(resetUserPassword, dispatch),
    
});
export default connect(mapStateToProps, mapDispatchToProps)(User);