import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import Footer from './Footer';

const AuthLayout = (props) => {

    return (
        <div className='login-page'>
            {/* <div className='login-box'> */}
            <div className="card card-outline card-primary">
                {props.children}
                <Footer />
            </div>
            {/* </div> */}
        </div>
    )

} 

const mapStateToProps = state => ({
    auth: state.auth
});
const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);